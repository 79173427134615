import { AllRegionCode, Region } from "../data/enum/Region";
import { BCPLocale } from "../helpers/rioti18n";

type SiteConfig = {
  rsoNonce: string;
  localStorageAuthToken: string;
  defaultLocale: BCPLocale;
  defaultRegion: AllRegionCode;
  riotbarEnv: string;
};

const siteConfig: SiteConfig = {
  rsoNonce: process.env.GATSBY_RSO_NONCE ?? "findyourchampion-wr.rso:nonce",
  localStorageAuthToken: process.env.GATSBY_LOCALSTORAGE_AUTH_TOKEN ?? "findyourchampion-wr.auth",
  defaultLocale: (process.env.GATSBY_DEFAULT_LOCALE as BCPLocale) ?? BCPLocale["en-us"],
  defaultRegion: (process.env.GATSBY_DEFAULT_REGION as AllRegionCode) ?? (Region.US as AllRegionCode),
  riotbarEnv: process.env.RIOTBAR_ENV ?? "staging",
};

export default siteConfig;
