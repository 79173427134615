module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/mnt/nfs/wwpub/static_sites/wildrift-fyc/wildrift-fyc-static-prod-blue/persistent/locale","languages":["id-id","ms-my","th-th","ko-kr","zh-tw","vi-vn","ja-jp","ru-ru","es-mx","tr-tr","it-it","pl-pl","fr-fr","pt-br","de-de","en-us","es-es","ar-ae","en-sg","en-gb"],"defaultLanguage":"en-us","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MZVD2VW","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"./src/gatsby"},
    }]
