import locale from "locale";
import { getRegionProperties } from "../config/regionConfig";
import siteConfig from "../config/siteConfig";
import ClientStorage from "../data/enum/ClientStorage";
import cookie from "../utils/cookie";
import { stripSlashes } from "../utils/string";
import { isValidUrl } from "../utils/url";
import { BCPLocale, isBCPLocale, riot2bcp } from "./rioti18n";

const detectFromAcceptLanguage = (): string | null => {
  let found = null;

  const cookieValue =
    cookie.read(ClientStorage.cookie.ACCEPT_LANGUAGE_OVERRIDE) || cookie.read(ClientStorage.cookie.ACCEPT_LANGUAGE);

  if (cookieValue) {
    const supported = new locale.Locales(Object.keys(BCPLocale));
    const locales = new locale.Locales(cookieValue);
    found = locales.best(supported);

    if (!!found) {
      found = found.toString();
    }
  }

  return found;
};

export default (): BCPLocale => {
  const hasUrlLocale = isValidUrl();
  const localeUrl = hasUrlLocale && stripSlashes(hasUrlLocale[0]);

  if (localeUrl && isBCPLocale(localeUrl)) return localeUrl;

  if (typeof window === `undefined`) return siteConfig.defaultLocale;

  const regionProps = getRegionProperties();

  let foundLocale = window.localStorage.getItem(ClientStorage.LOCALSTORAGE_DEFINED_LANGUAGE);
  let fromHistory = true;

  if (!foundLocale) {
    foundLocale = detectFromAcceptLanguage();
    fromHistory = false;
  }

  if (!foundLocale) {
    foundLocale = siteConfig.defaultLocale;
    fromHistory = false;
  }

  if (!fromHistory && regionProps.lang) {
    foundLocale = riot2bcp(regionProps.lang);
  }

  window.localStorage.setItem(ClientStorage.LOCALSTORAGE_DEFINED_LANGUAGE, foundLocale);
  return foundLocale as BCPLocale;
};
