/* eslint-disable @typescript-eslint/camelcase */
import { css, FlattenSimpleInterpolation } from "styled-components";
import { BCPLocale } from "../../helpers/rioti18n";
/*
  font @import rules are not working with styled-component global
  rules at this time, so we use css or Helmet to inject them as a global style.
*/
// Example:
// export default = createGlobalStyle`
//   @import url("https://lolstatic-a.akamaihd.net/webfonts/live/css/lor/fonts.css");
// `;
// Or fill up the theme-fonts.css file to load custom fonts.
import "./theme-fonts.css";

/** Colors */

export const color_primary_1 = "#f5d765";
export const color_primary_2 = "#ff9000";
export const color_primary_3 = "#ec0048";
export const color_primary_4 = "#8a45b6";
export const color_primary_5 = "#59a2d0";
export const color_primary_6 = "#c5fa8xa";
export const color_primary_7 = "#272727";
export const color_primary_8 = "#d7d3ca";
export const color_secondary_1 = "#000000";
export const color_secondary_2 = "#ffffff";

const fonts = {
  fontBeaufortforLOLBold: `"BeaufortforLOL-Bold", arial, georgia, sans-serif`,
  fontBeaufortforLOLHeavy: `"BeaufortforLOL-Heavy", arial, georgia, sans-serif`,
  fontGTAmericaCompressedBlack: `"GT-America-Compressed-Black", arial, georgia, sans-serif`,
  fontGTAmericaCompressedBold: `"GT-America-Compressed-Bold", sans-serif`,
  fontGTAmericaCompressedMedium: `"GT-America-Compressed-Medium", arial, georgia, sans-serif`,
  fontSpiegelRegular: `"Spiegel-Regular", arial, georgia, sans-serif`,
  fontSpiegelSemiBold: `"Spiegel-SemiBold", arial, georgia, sans-serif`,
  fontSpiegelBold: `"Spiegel-Bold", arial, georgia, sans-serif`,
  fontCairo: `"Cairo", arial, georgia, sans-serif`,
  fontNeueFrutigerThaiModern: `"NeueFrutigerThaiModern", "GT-America-Compressed-Bold", arial, georgia, sans-serif`,
  fontNeueFrutigerThaiTrad: `"NeueFrutigerThaiTrad", "Spiegel-Regular", arial, georgia, sans-serif`,
  fontRixSGoB: `"RixSGoB", "GT-America-Compressed-Bold", arial, georgia, sans-serif`,
  fontRixSGoM: `"RixSGoM", "GT-America-Compressed-Medium", arial, georgia, sans-serif`,
  fontRixSGoL: `"RixSGoL", "Spiegel-Regular", arial, georgia, sans-serif`,
  fontRoboto: `"Roboto", "Spiegel-Regular", arial, georgia, sans-serif`,
  fontRobotoCondensed: `"RobotoCondensed", "GT-America-Compressed-Bold", arial, georgia, sans-serif`,
  fontFZLTCH: `"WildRift-TraditionalChinese", "GT-America-Compressed-Medium", arial, georgia, sans-serif`,
  fontBeaufortforLOLJaBold: `"BeaufortforLOLJa-Bold", "GT-America-Compressed-Medium", arial, georgia, sans-serif`,
  fontBeaufortforLOLJaRegular: `"BeaufortforLOLJa-Regular", "GT-America-Compressed-Medium", arial, georgia, sans-serif`,
  fontSpiegelJaRegular: `"SpiegelJa-Regular", "Spiegel-Regular", arial, georgia, sans-serif`,
  fontSpiegelJaBold: `"SpiegelJa-Bold", "Spiegel-SemiBold", arial, georgia, sans-serif`,
};

/** Themes */
const mainThemeColors = {
  colorBlack: `#111`,
  colorWhite: `#fff`,
  colorGray: `#888`,
  colorElectricBlue: `#32c8ff`,
  colorRoyalBlue: `#2858f0`,
  colorMidnightBlue: `#141e37`,
  colorLightPurple: `#6e87ff`,
  colorDarkPurple: `#551ed7`,
  colorLightRed: `#ff5f5f`,
  colorDarkRed: `#981959`,
  colorLightYellow: `#ffa523`,
  colorDarkYellow: `#dc500f`,
  colorGold: `#c89b3c`,
  colorErrorRed: `#cc3c55`,
};

export type MainThemeColors = typeof mainThemeColors;
export const MainTheme = {
  ...mainThemeColors,
  supportsUppercasing: true,
  colorTransition: `color 150ms ease, background 150ms ease`,
};

// named sizes for screen widths.
export const size = {
  mobile: "420px",
  tabletPortrait: "768px",
  tabletLandscape: "1024px",
  laptopSmall: "1280px",
  laptopLarge: "1440px",
  desktop: "1920px",
};

// Named devices for media queries.
export const device = {
  minWidth420: `(min-width: ${size.mobile})`,
  minWidth768: `(min-width: ${size.tabletPortrait})`,
  minWidth1024: `(min-width: ${size.tabletLandscape})`,
  minWidth1280: `(min-width: ${size.laptopSmall})`,
  minWidth1440: `(min-width: ${size.laptopLarge})`,
  minWidth1920: `(min-width: ${size.desktop})`,
};

const localizeHeadingFontStyles: Partial<Record<BCPLocale, FlattenSimpleInterpolation>> = {
  "en-us": css`
    font-family: ${fonts.fontGTAmericaCompressedBold};
  `,
  "fr-fr": css`
    line-height: 1.1;
  `,
  "ar-ae": css`
    font-family: ${fonts.fontCairo};
  `,
  "th-th": css`
    font-family: ${fonts.fontNeueFrutigerThaiTrad};
    font-weight: 700;
    line-height: 1.55;
    font-style: normal;
  `,
  "ko-kr": css`
    font-family: ${fonts.fontRixSGoM};
    font-style: normal;
    word-break: keep-all;
  `,
  "zh-tw": css`
    font-family: ${fonts.fontFZLTCH};
    font-style: normal;
    font-weight: 700;
  `,
  "ru-ru": css`
    font-family: ${fonts.fontBeaufortforLOLHeavy};
  `,
  "vi-vn": css`
    font-family: ${fonts.fontRobotoCondensed};
    font-weight: 700;
    line-height: 1.2 !important;
  `,
  "ja-jp": css`
    font-family: ${fonts.fontRobotoCondensed};
  `,
};

const localizeCopyFontStyles: Partial<Record<BCPLocale, FlattenSimpleInterpolation>> = {
  "en-us": css`
    font-family: ${fonts.fontSpiegelRegular};
  `,
  "ar-ae": css`
    font-family: ${fonts.fontCairo};
  `,
  "th-th": css`
    font-family: ${fonts.fontNeueFrutigerThaiModern};
  `,
  "ko-kr": css`
    font-family: ${fonts.fontRixSGoM};
  `,
  "vi-vn": css`
    font-family: ${fonts.fontRoboto};
  `,
  "zh-tw": css`
    font-family: ${fonts.fontFZLTCH};
    font-weight: 400;
  `,
  "ja-jp": css`
    font-family: ${fonts.fontSpiegelJaRegular};
    line-height: 1.3 !important;
  `,
};
const getLocalizeStyle = (
  locale: BCPLocale,
  localizeFontStyles: Partial<Record<BCPLocale, FlattenSimpleInterpolation>>,
): FlattenSimpleInterpolation =>
  localizeFontStyles[locale] ?? (localizeFontStyles["en-us"] as FlattenSimpleInterpolation);

/** Mixins */

// use this mix-in instead of `text-transform: uppercase`
export const TextTransformUppercase = css`
  text-transform: ${(props): string => (props.theme.supportsUppercasing ? "uppercase" : "none")};
`;

// Heading font for all locales
export const fontHeading = css`
  font-family: ${fonts.fontGTAmericaCompressedBold};
  font-style: italic;
  color: ${(props): string => props.theme.colorWhite};
  ${TextTransformUppercase};

  ${(props): FlattenSimpleInterpolation => getLocalizeStyle(props.theme.locale, localizeHeadingFontStyles)};
`;

// Copy font for all locales
export const fontCopy = css`
  font-family: ${fonts.fontSpiegelRegular};
  ${(props): FlattenSimpleInterpolation => getLocalizeStyle(props.theme.locale, localizeCopyFontStyles)};
`;
