import ClientStorage from "../data/enum/ClientStorage";
import { AllRegionCode } from "../data/enum/Region";
import { RiotLocale } from "../helpers/rioti18n";
import cookie from "../utils/cookie";
import siteConfig from "./siteConfig";

interface RegionSetting {
  alpha3: string;
  lang?: RiotLocale;
  gdpr?: boolean;
}

export const regionConfig: { [key in AllRegionCode]: RegionSetting } = {
  AU: { alpha3: "AUS", gdpr: false, lang: RiotLocale.en_GB },
  NZ: { alpha3: "NZL", gdpr: false, lang: RiotLocale.en_GB },
  GU: { alpha3: "GUM" },
  MP: { alpha3: "MNP" },
  FJ: { alpha3: "FJI" },
  MH: { alpha3: "MHL" },
  VU: { alpha3: "VUT" },
  CK: { alpha3: "COK" },
  PG: { alpha3: "PNG" },
  AS: { alpha3: "ASM" },
  SB: { alpha3: "SLB" },
  TO: { alpha3: "TON" },
  BR: { alpha3: "BRA", gdpr: false, lang: RiotLocale.pt_BR },
  RE: { alpha3: "REU", gdpr: true, lang: RiotLocale.fr_FR },
  GP: { alpha3: "GLP", gdpr: true, lang: RiotLocale.fr_FR },
  MQ: { alpha3: "MTQ", gdpr: true, lang: RiotLocale.fr_FR },
  GF: { alpha3: "GUF", gdpr: true, lang: RiotLocale.fr_FR },
  FR: { alpha3: "FRA", gdpr: true, lang: RiotLocale.fr_FR },
  MC: { alpha3: "MCO", gdpr: true, lang: RiotLocale.fr_FR },
  DE: { alpha3: "DEU", gdpr: true, lang: RiotLocale.de_DE },
  AT: { alpha3: "AUT", gdpr: true, lang: RiotLocale.de_DE },
  CH: { alpha3: "CHE", gdpr: true, lang: RiotLocale.de_DE }, // ageRestricted: true },
  LU: { alpha3: "LUX", gdpr: true }, //, ageRestricted: true },
  LI: { alpha3: "LIE", gdpr: true },
  FO: { alpha3: "FRO", gdpr: true },
  CW: { alpha3: "CUW", gdpr: true },
  SX: { alpha3: "SXM", gdpr: true },
  VC: { alpha3: "VCT", gdpr: true },
  VG: { alpha3: "VGB", gdpr: true },
  KN: { alpha3: "KNA", gdpr: true },
  HT: { alpha3: "HTI", gdpr: true },
  NC: { alpha3: "NCL", gdpr: true },
  PF: { alpha3: "PYF", gdpr: true },
  MN: { alpha3: "MNG", gdpr: true },
  PM: { alpha3: "SPM", gdpr: true },
  MM: { alpha3: "MMR", gdpr: true },
  PL: { alpha3: "POL", gdpr: true, lang: RiotLocale.pl_PL },
  ES: { alpha3: "ESP", gdpr: true, lang: RiotLocale.es_ES },
  SE: { alpha3: "SWE", gdpr: true }, // ageRestricted: true },
  IT: { alpha3: "ITA", gdpr: true, lang: RiotLocale.it_IT },
  NL: { alpha3: "NLD", gdpr: true },
  DK: { alpha3: "DNK", gdpr: true },
  NO: { alpha3: "NOR", gdpr: true }, // ageRestricted: true },
  RO: { alpha3: "ROU", gdpr: true },
  BE: { alpha3: "BEL", gdpr: true },
  GR: { alpha3: "GRC", gdpr: true },
  PT: { alpha3: "PRT", gdpr: true, lang: RiotLocale.pt_BR },
  HU: { alpha3: "HUN", gdpr: true },
  FI: { alpha3: "FIN", gdpr: true },
  CZ: { alpha3: "CZE", gdpr: true },
  BG: { alpha3: "BGR", gdpr: true },
  RS: { alpha3: "SRB", gdpr: true }, // ageRestricted: true },
  HR: { alpha3: "HRV", gdpr: true },
  LT: { alpha3: "LTU", gdpr: true },
  IE: { alpha3: "IRL", gdpr: true },
  ZA: { alpha3: "ZAF", gdpr: true },
  SI: { alpha3: "SVN", gdpr: true },
  EE: { alpha3: "EST", gdpr: true },
  LV: { alpha3: "LVA", gdpr: true },
  SK: { alpha3: "SVK", gdpr: true },
  BA: { alpha3: "BIH", gdpr: true }, // ageRestricted: true },
  IS: { alpha3: "ISL", gdpr: true }, // ageRestricted: true },
  MK: { alpha3: "MKD", gdpr: true }, //  ageRestricted: true },
  ME: { alpha3: "MNE", gdpr: true }, //  ageRestricted: true },
  AL: { alpha3: "ALB", gdpr: true }, //  ageRestricted: true },
  AD: { alpha3: "AND", gdpr: true },
  GL: { alpha3: "GRL", gdpr: true },
  NA: { alpha3: "NAM", gdpr: true },
  MV: { alpha3: "MDV", gdpr: true },
  KE: { alpha3: "KEN", gdpr: true },
  SR: { alpha3: "SUR", gdpr: true },
  GY: { alpha3: "GUY", gdpr: true },
  AG: { alpha3: "ATG", gdpr: true },
  NG: { alpha3: "NGA", gdpr: true },
  SN: { alpha3: "SEN", gdpr: true },
  YT: { alpha3: "MYT", gdpr: true },
  MG: { alpha3: "MDG", gdpr: true },
  LC: { alpha3: "LCA", gdpr: true },
  GD: { alpha3: "GRD", gdpr: true },
  GH: { alpha3: "GHA", gdpr: true },
  CI: { alpha3: "CIV", gdpr: true },
  AO: { alpha3: "AGO", gdpr: true },
  MZ: { alpha3: "MOZ", gdpr: true },
  MF: { alpha3: "MAF", gdpr: true },
  SM: { alpha3: "SMR", gdpr: true },
  ZW: { alpha3: "ZWE", gdpr: true, lang: RiotLocale.en_GB },
  BQ: { alpha3: "BES", gdpr: true },
  BW: { alpha3: "BWA", gdpr: true },
  ZM: { alpha3: "ZMB", gdpr: true },
  CM: { alpha3: "CMR", gdpr: true },
  DJ: { alpha3: "DJI", gdpr: true },
  TZ: { alpha3: "TZA", gdpr: true },
  UG: { alpha3: "UGA", gdpr: true },
  SD: { alpha3: "SDN", gdpr: true },
  GA: { alpha3: "GAB", gdpr: true },
  ET: { alpha3: "ETH", gdpr: true },
  SC: { alpha3: "SYC", gdpr: true },
  MR: { alpha3: "MRT", gdpr: true },
  TC: { alpha3: "TCA", gdpr: true },
  CG: { alpha3: "COG", gdpr: true },
  GN: { alpha3: "GIN", gdpr: true },
  CV: { alpha3: "CPV", gdpr: true },
  BJ: { alpha3: "BEN", gdpr: true },
  ML: { alpha3: "MLI", gdpr: true },
  AI: { alpha3: "AIA", gdpr: true },
  TG: { alpha3: "TGO", gdpr: true },
  NE: { alpha3: "NER", gdpr: true },
  BF: { alpha3: "BFA", gdpr: true },
  SZ: { alpha3: "SWZ", gdpr: true },
  LR: { alpha3: "LBR", gdpr: true },
  LS: { alpha3: "LSO", gdpr: true },
  GQ: { alpha3: "GNQ", gdpr: true },
  RW: { alpha3: "RWA", gdpr: true },
  SO: { alpha3: "SOM", gdpr: true },
  SL: { alpha3: "SLE", gdpr: true },
  MW: { alpha3: "MWI", gdpr: true },
  GB: { alpha3: "GBR", gdpr: true, lang: RiotLocale.en_GB },
  MT: { alpha3: "MLT", gdpr: true },
  JE: { alpha3: "JEY", gdpr: true },
  GG: { alpha3: "GGY", gdpr: true },
  IM: { alpha3: "IMN", gdpr: true },
  GI: { alpha3: "GIB", gdpr: true },
  HK: { alpha3: "HKG", gdpr: false, lang: RiotLocale.zh_TW },
  JP: { alpha3: "JPN", gdpr: false, lang: RiotLocale.ja_JP },
  KR: { alpha3: "KOR", gdpr: false, lang: RiotLocale.ko_KR },
  MX: { alpha3: "MEX", gdpr: false, lang: RiotLocale.es_MX },
  CL: { alpha3: "CHL", gdpr: false, lang: RiotLocale.es_MX },
  AR: { alpha3: "ARG", gdpr: false, lang: RiotLocale.es_MX },
  CO: { alpha3: "COL", gdpr: false, lang: RiotLocale.es_MX },
  CR: { alpha3: "CRI", gdpr: false, lang: RiotLocale.es_MX },
  PE: { alpha3: "PER", gdpr: false, lang: RiotLocale.es_MX },
  UY: { alpha3: "URY", gdpr: false, lang: RiotLocale.es_MX },
  EC: { alpha3: "ECU", gdpr: false, lang: RiotLocale.es_MX },
  DO: { alpha3: "DOM", gdpr: false, lang: RiotLocale.es_MX },
  PA: { alpha3: "PAN", gdpr: false, lang: RiotLocale.es_MX },
  PR: { alpha3: "PRI", gdpr: false, lang: RiotLocale.es_MX },
  VE: { alpha3: "VEN", gdpr: false, lang: RiotLocale.es_MX },
  BO: { alpha3: "BOL", gdpr: false, lang: RiotLocale.es_MX },
  SV: { alpha3: "SLV", gdpr: false, lang: RiotLocale.es_MX },
  GT: { alpha3: "GTM", gdpr: false, lang: RiotLocale.es_MX },
  PY: { alpha3: "PRY", gdpr: false, lang: RiotLocale.es_MX },
  HN: { alpha3: "HND", gdpr: false, lang: RiotLocale.es_MX },
  NI: { alpha3: "NIC", gdpr: false, lang: RiotLocale.es_MX },
  DM: { alpha3: "DMA", gdpr: false, lang: RiotLocale.es_MX },
  CU: { alpha3: "CUB", gdpr: false, lang: RiotLocale.es_MX },
  BD: { alpha3: "BGD" },
  NP: { alpha3: "NPL" },
  IN: { alpha3: "IND" },
  LK: { alpha3: "LKA" },
  MU: { alpha3: "MUS" },
  PK: { alpha3: "PAK" },
  BT: { alpha3: "BTN" },
  KH: { alpha3: "KHM" },
  TL: { alpha3: "TLS" },
  LA: { alpha3: "LAO" },
  CN: { alpha3: "CHN" },
  US: { alpha3: "USA", gdpr: false, lang: RiotLocale.en_US },
  CA: { alpha3: "CAN", gdpr: false, lang: RiotLocale.en_US },
  TT: { alpha3: "TTO" },
  JM: { alpha3: "JAM" },
  BS: { alpha3: "BHS" },
  BB: { alpha3: "BRB" },
  AW: { alpha3: "ABW" },
  KY: { alpha3: "CYM" },
  BM: { alpha3: "BMU" },
  VI: { alpha3: "VIR", gdpr: false, lang: RiotLocale.vi_VN },
  BZ: { alpha3: "BLZ" },
  VN: { alpha3: "VNM" },
  TW: { alpha3: "TWN", gdpr: false, lang: RiotLocale.zh_TW },
  PH: { alpha3: "PHL", gdpr: false, lang: RiotLocale.en_SG },
  SG: { alpha3: "SGP", gdpr: false, lang: RiotLocale.en_SG },
  TH: { alpha3: "THA", gdpr: false, lang: RiotLocale.th_TH },
  ID: { alpha3: "IDN", gdpr: false, lang: RiotLocale.id_ID },
  MY: { alpha3: "MYS", gdpr: false, lang: RiotLocale.ms_MY },
  AE: { alpha3: "ARE", gdpr: false, lang: RiotLocale.ar_AE },
  SA: { alpha3: "SAU", gdpr: false, lang: RiotLocale.ar_AE },
  MA: { alpha3: "MAR", gdpr: false, lang: RiotLocale.ar_AE },
  IL: { alpha3: "ISR" }, // , ageRestricted: true },
  KW: { alpha3: "KWT", gdpr: false, lang: RiotLocale.ar_AE },
  EG: { alpha3: "EGY", gdpr: false, lang: RiotLocale.ar_AE },
  QA: { alpha3: "QAT", gdpr: false, lang: RiotLocale.ar_AE },
  LB: { alpha3: "LBN", gdpr: false, lang: RiotLocale.ar_AE },
  BN: { alpha3: "BRN", gdpr: false, lang: RiotLocale.ar_AE },
  JO: { alpha3: "JOR", gdpr: false, lang: RiotLocale.ar_AE },
  TN: { alpha3: "TUN", gdpr: false, lang: RiotLocale.ar_AE },
  OM: { alpha3: "OMN", gdpr: false, lang: RiotLocale.ar_AE },
  BH: { alpha3: "BHR", gdpr: false, lang: RiotLocale.ar_AE },
  DZ: { alpha3: "DZA", gdpr: false, lang: RiotLocale.ar_AE },
  IQ: { alpha3: "IRQ", gdpr: false, lang: RiotLocale.ar_AE },
  PS: { alpha3: "PSE" },
  AF: { alpha3: "AFG" },
  SY: { alpha3: "SYR" },
  LY: { alpha3: "LBY" },
  YE: { alpha3: "YEM" },
  IR: { alpha3: "IRN" },
  MO: { alpha3: "MAC", gdpr: false, lang: RiotLocale.zh_TW },
  RU: { alpha3: "RUS", gdpr: false, lang: RiotLocale.ru_RU },
  UA: { alpha3: "UKR" },
  BY: { alpha3: "BLR" },
  GE: { alpha3: "GEO" },
  KZ: { alpha3: "KAZ" },
  MD: { alpha3: "MDA" }, // ageRestricted: true },
  AM: { alpha3: "ARM" },
  AZ: { alpha3: "AZE" },
  KG: { alpha3: "KGZ" },
  UZ: { alpha3: "UZB" },
  TJ: { alpha3: "TJK" },
  TM: { alpha3: "TKM" },
  TR: { alpha3: "TUR", gdpr: true, lang: RiotLocale.tr_TR },
  CY: { alpha3: "CYP" },
  XK: { alpha3: "XXK" }, //, ageRestricted: true },
  VA: { alpha3: "VAT" }, //, ageRestricted: true },
};

export const getRegion = (): AllRegionCode => {
  const geo = (
    cookie.read(ClientStorage.cookie.REGION_OVERRIDE) ||
    cookie.read(ClientStorage.cookie.REGION) ||
    siteConfig.defaultRegion
  ).toUpperCase();

  if (Object.prototype.hasOwnProperty.call(regionConfig, geo)) {
    return geo as AllRegionCode;
  }
  return siteConfig.defaultRegion;
};

export const getRegionProperties = (): RegionSetting => {
  const properties = regionConfig[getRegion()];
  return {
    // defaults
    ...{
      gdpr: false,
      lang: RiotLocale.en_GB,
    },
    ...properties,
  };
};
