const ClientStorage = {
  cookie: {
    REGION: "geo",
    REGION_OVERRIDE: "geo_debug",

    ACCEPT_LANGUAGE: "lang",
    ACCEPT_LANGUAGE_OVERRIDE: "lang_debug",
  },

  LOCALSTORAGE_DEFINED_LANGUAGE: "findyourchampion-wr.localStorageLanguageSelectOverride",
};

export default ClientStorage;
