import RiotBarLoader from "@riotgames/riotbar-react";
import { WrapPageElementBrowserArgs } from "gatsby";
import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { MainTheme } from "../../components/Layout/theme";
import { BCPLocale, supportsUppercasing } from "../../helpers/rioti18n";
import { isRTL } from "../../utils/isRTL";

interface PageContext {
  language: BCPLocale;
}

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs<null, PageContext>): JSX.Element => {
  const locale = props.pageContext.language;
  // Load the main theme.
  const theme: DefaultTheme = {
    ...MainTheme,
    // Uppercasing is not supported in some languages.
    supportsUppercasing: supportsUppercasing(locale),
    locale,
    isRTL: isRTL(locale),
  };
  return (
    <ThemeProvider theme={theme}>
      <RiotBarLoader locale={locale} product="wildrift-cb3" environment={process.env.GATSBY_RIOTBAR_ENV ?? "staging"} />
      {element}
      <footer id="riotbar-footer" />
    </ThemeProvider>
  );
};
